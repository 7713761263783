<template>
  <div class="standard-page">
    <!-- The header buttons -->
    <div class="d-flex justify-end mb-4">
      <!-- The button to delete selected items -->
      <v-btn
        v-if="selectedItems.length"
        depressed
        class="mr-3"
        color="primary"
        :loading="isMakingRequest"
        :disabled="isMakingRequest"
        @click="shouldShowDeleteDialog = true"
      >
        <v-icon left> delete </v-icon>

        Delete
      </v-btn>

      <!-- Button to show the create popup -->
      <v-btn
        depressed
        color="primary"
        :loading="isMakingRequest"
        :disabled="isMakingRequest"
        @click="shouldShowCreateDialog = true"
      >
        <v-icon left> add </v-icon>

        Create Report
      </v-btn>
    </div>

    <v-card class="box-shadow-soft rounded rounded-bl-0 rounded-br-0 px-3 py-6">
      <v-row>
        <!-- Show the selectors -->
        <v-col
          cols="12"
          md="6"
          lg="8"
        >
          <v-row>
            <v-col
              v-if="isPartOfTeam"
              cols="6"
              md="4"
              lg="3"
            >
              <v-select
                v-model="selectedCreatedBy"
                label="Created By"
                outlined
                dense
                hide-details
                :items="createdByOptions"
              ></v-select>
            </v-col>

            <v-col
              cols="6"
              md="4"
              lg="3"
            >
              <platform-selector
                v-model="selectedPlatform"
                label="Platform"
                small
                show-all
                outlined
                full-width
              />
            </v-col>

            <v-col
              cols="6"
              md="4"
              lg="3"
            >
              <v-select
                v-model="selectedType"
                label="Type"
                outlined
                dense
                hide-details
                :items="typeOptions"
              ></v-select>
            </v-col>

            <v-col
              cols="6"
              md="4"
              lg="3"
            >
              <v-select
                v-model="selectedStatus"
                label="Status"
                outlined
                dense
                hide-details
                :items="statusOptions"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-spacer />

        <!-- Show the input -->
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="searchQuery"
            label="Search"
            placeholder="Report title"
            outlined
            dense
            hide-details
            clearable
            append-icon="search"
            @keypress.enter="currentPage = 1"
            @click:append="currentPage = 1"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>

    <!-- Show the results table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableHeaders"
      :items="tableItems"
      :server-items-length="response.total"
      :page="currentPage"
      :items-per-page="queryPerPage"
      @update:page="currentPage = $event"
      @update:items-per-page="queryPerPage = $event"
      show-select
      selectable-key="isSelectable"
      mobile-breakpoint="100"
      class="box-shadow-soft social-sentiments-data-table"
    >
      <!-- For Platform -->
      <template v-slot:item.platform="{ item }">
        <div class="d-flex">
          <v-img height="20" width="20" max-width="20" :src="platformIconMap[item.platform]" />
        </div>
      </template>

      <!-- For Input Query -->
      <template v-slot:item.query="{ item }">
        <!-- If it's for a profile -->
        <query-chip
          v-if="item.for === 'profile'"
          :model="item"
        />

        <!-- If it's for a post -->
        <template v-else>
          <!-- If we have the preview -->
          <div
            v-if="item.post_preview && item.influencer_preview"
            class="d-flex align-center"
          >
            <profile-chip
              :data="item.influencer_preview"
              :platform="item.platform"
            />

            <div class="pointer pl-2">
              <v-img
                @click="previewThumbnail = item.post_preview.thumbnail; shouldShowPreviewDialog = true;"
                :src="item.post_preview.thumbnail"
                crossorigin="anonymous"
                max-width="32"
                height="32"
                cover
              />
            </div>
          </div>

          <!-- Otherwise -->
          <link-chip v-else :url="item.query" />
        </template>
      </template>

      <!-- For Score -->
      <template v-slot:item.score="{ item }">
        <score-chip :score="item.score" class="cursor-pointer" />
      </template>

      <!-- For current item progress -->
      <template v-slot:item.status="{ item }">
        <status-chip
          :status="item.status"
          :width="96"
        />
      </template>

      <!-- To show a button for the graph -->
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-end align-center">
          <v-btn
            small
            depressed
            color="primary"
            :disabled="item.status !== 'completed'"
            @click="$router.push({ name: 'SocialSentimentsReport', params: { uuid: item.uuid } })"
          >
            View
            <v-icon small right> open_in_new </v-icon>
          </v-btn>

          <!-- Show menu options -->
          <v-menu
            transition="slide-y-transition"
            :close-on-content-click="true"
            offset-y
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-3"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <!-- Show the menu options -->
            <v-list width="140" dense>
              <!-- Show the edit button -->
              <v-list-item
                @click="showEditDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Edit
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the delete button -->
              <v-list-item
                v-if="item.status === 'failed' || item.status === 'completed'"
                @click="showDeleteDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Delete
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the download button -->
              <v-list-item
                v-if="item.status === 'completed'"
                @click="triggerDownload(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Download PDF
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>

    <!-- Show the preview dialog -->
    <v-dialog
      v-model="shouldShowPreviewDialog"
      max-width="338"
    >
      <v-card-text
        v-if="previewThumbnail"
        class="px-0 py-0"
      >
        <v-img
          :src="previewThumbnail"
          class="white"
          max-width="338"
          max-height="600"
          contain
        />
      </v-card-text>
    </v-dialog>

    <!-- Show the delete confirmation dialog -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      :persistent="isMakingRequest"
      max-width="400"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <v-icon dark class="mr-3"> delete </v-icon>

          Are you sure?
        </v-card-title>

        <v-card-text class="pt-4">
          You are about to delete {{ selectedItems.length }} social sentiment {{ selectedItems.length > 1 ? "reports" : "report" }}.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowDeleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            text
            color="primary"
            :loading="isMakingRequest"
            :disabled="isMakingRequest"
            @click="handleDeleteSubmit"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the edit dialog -->
    <v-dialog
      v-model="shouldShowEditDialog"
      max-width="400"
    >
      <v-card v-if="selectedItem">
        <v-card-title class="d-flex justify-space-between primary white--text pb-4">
          <div class="d-flex align-center">
            Edit this report
          </div>

          <v-btn
            text
            color="buttonPrimaryText"
            class="primary lighten-1"
            @click="copyReportLink(selectedItem)"
          >
            <v-icon left>
              link
            </v-icon>
            Copy URL
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-6">
          <v-text-field
            v-model="form.title"
            label="Report Title"
            placeholder="Optional"
            @input="$v.form.title.$touch()"
            @blur="$v.form.title.$touch()"
            :hide-details="!$v.form.title.$anyError"
            :error-messages="$v.form.title.$anyError ? ['Please enter a title under 100 characters'] : null"
            class="mb-6"
            outlined
            dense
          ></v-text-field>

          <v-select
            v-model="form.sharingAccess"
            label="Sharing Access"
            :items="sharingAccessOptions"
            :hint="sharingAccessHint"
            class="mt-3"
            outlined
            dense
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            @click="shouldShowEditDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            @click="handleEditSubmit"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <form-component
      v-model="shouldShowCreateDialog"
      @created="fetchItems"
    />
  </div>
</template>

<script>
// Import helper functions
import messageEvents from "@/helpers/messageEvents"
import { maxLength } from "vuelidate/lib/validators"

// Import children components
const LinkChip = () => import(/* webpackChunkName: "chip-link" */ "@/blocks/common/LinkChip.vue")
const QueryChip = () => import(/* webpackChunkName: "chip-query" */ "@/components/social-sentiments/QueryChip")
const ScoreChip = () => import(/* webpackChunkName: "chip-score" */ "@/components/social-sentiments/ScoreChip")
const StatusChip = () => import(/* webpackChunkName: "status-chip" */ "@/blocks/common/StatusChip")
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip.vue')
const FormComponent = () => import(/* webpackChunkName: "social-sentiments-form-component" */ "@/components/social-sentiments/Form.vue")
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector")

// The subscriptionId for message events
const subscriptionId = Symbol("SocialSentiments/History")

// Export this SFC
export default {
  // Name of this component
  name: "SocialSentimentsHistory",

  // Register the component
  components: {
    LinkChip,
    QueryChip,
    ScoreChip,
    StatusChip,
    ProfileChip,
    FormComponent,
    PlatformSelector
  },

  // Local data variables
  data: () => ({
    // Whether or not we're loading
    isMakingRequest: false,

    // The selected items to be deleted
    selectedItems: [],
    shouldShowDeleteDialog: false,
    shouldShowCreateDialog: false,

    // The selected item to be edited/shown/retried
    selectedItem: null,
    shouldShowErrorsDialog: false,
    shouldShowRetryDialog: false,
    shouldShowEditDialog: false,

    // The preview dialog handlers
    shouldShowPreviewDialog: false,
    previewThumbnail: null,

    // The result table headers
    tableHeaders: [
      {
        text: "",
        value: "platform",
        sortable: false
      },
      {
        text: "",
        value: "query",
        sortable: false
      },
      {
        text: "Title",
        value: "title",
        sortable: false,
        width: 160,
      },
      {
        text: "Score",
        value: "score",
        sortable: false,
        align: "center"
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
        align: "center"
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        align: "end"
      }
    ],

    // The edit form
    form: {
      title: "",
      sharingAccess: "self"
    },

    statusOptions: [
      {
        text: "All",
        value: null
      },
      {
        text: "Pending",
        value: "pending"
      },
      {
        text: "Aggregating",
        value: "aggregating"
      },
      {
        text: "Processing",
        value: "processing"
      },
      {
        text: "Completed",
        value: "completed"
      },
      {
        text: "Failed",
        value: "failed"
      }
    ],

    typeOptions: [
      {
        text: "All",
        value: null
      },
      {
        text: "Profile",
        value: "profile"
      },
      {
        text: "Post",
        value: "post"
      }
    ]
  }),

  // Define local computable properties
  computed: {
    /**
     * Get API response from the Vuex store
     *
     * @returns {Object}
     */
    response() {
      return this.$store.getters["socialSentiments/response"]
    },

    /**
     * Get the queries from Vuex store
     *
     * @returns {Object}
     */
    query() {
      return this.$store.getters["socialSentiments/query"]
    },

    /**
     * Get the createdBy options
     *
     * @returns {Array}
     */
    tableItems() {
      return this.response.data.map((item) => ({
        ...item,
        isSelectable: item.status === "failed" || item.status === "completed"
      }))
    },

    /**
     * Get the current page number in pagination
     */
    currentPage: {
      get() {
        return this.query.page
      },

      set(value) {
        this.$store.dispatch("socialSentiments/updateQuery", {
          key: "page",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems(false)
      }
    },

    /**
     * Get the number of items per page
     */
    queryPerPage: {
      get() {
        return this.query.perPage
      },

      set(value) {
        this.$store.dispatch("socialSentiments/updateQuery", {
          key: "perPage",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },


    /**
     * Get the selected createdBy
     */
     selectedCreatedBy: {
      get() {
        return this.query.createdBy
      },

      set(value) {
        this.$store.dispatch("socialSentiments/updateQuery", {
          key: "createdBy",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the selected type
     */
    selectedType: {
      get() {
        return this.query.type
      },

      set(value) {
        this.$store.dispatch("socialSentiments/updateQuery", {
          key: "type",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the selected status
     */
    selectedStatus: {
      get() {
        return this.query.status
      },

      set(value) {
        this.$store.dispatch("socialSentiments/updateQuery", {
          key: "status",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the selected platform
     */
    selectedPlatform: {
      get() {
        return this.query.platform
      },

      set(value) {
        this.$store.dispatch("socialSentiments/updateQuery", {
          key: "platform",
          value
        })

        // Dispatch action to fetch items
        this.fetchItems()
      }
    },

    /**
     * Get the search query
     */
    searchQuery: {
      get() {
        return this.query.search
      },

      set(value) {
        // If the value is different
        if (this.query.search === value) return

        this.$store.dispatch("socialSentiments/updateQuery", {
          key: "search",
          value
        })
      }
    }
  },

  // Define vuelidate validation rules
  validations: {
    form: {
      title: {
        maxLength: maxLength(100)
      }
    }
  },

  // Method functions to be called from template and script
  methods: {
    /**
     * Fetch the items
     *
     * @param {Boolean} resetPage
     * @returns {void}
     */
    async fetchItems(resetPage = true) {
      // If the request is already being made
      if (this.isMakingRequest) return

      // If we need to reset the page
      if (resetPage) {
        this.currentPage = 1
      }

      // Mark as loading
      this.isMakingRequest = true
      // Dispatch action to fetch items
      await this.$store.dispatch("socialSentiments/fetchItems")
      // Mark as not loading
      this.isMakingRequest = false
    },

    /**
     * Show the delete dialog
     *
     * @param {Object} item
     * @returns {void}
     */
    showDeleteDialog(item) {
      this.selectedItems = [item]
      this.shouldShowDeleteDialog = true
    },

    /**
     * Delete the selected items
     *
     * @returns {void}
     */
    async handleDeleteSubmit() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Go through each selected items
      for (const item of this.selectedItems) {
        // Use vuex store action
        await this.$store.dispatch("socialSentiments/deleteItem", {
          id: item.id,
          showToast: false,
          fetchItems: false
        })
      }

      // Hide the loader
      this.$store.dispatch("loaders/remove", loaderId)
      this.isMakingRequest = false

      // Reset the selected items
      this.selectedItems = []

      // Hide the dialog
      this.shouldShowDeleteDialog = false

      // Refresh the items
      this.fetchItems(false)
    },

    /**
     * Show the edit dialog
     *
     * @param {Object} item
     * @returns {void}
     */
     showEditDialog(item) {
      // Set the form values
      this.form.title = item.title || ""
      this.form.sharingAccess = item.sharing_access

      this.$v.form.$reset()

      this.selectedItem = item
      this.shouldShowEditDialog = true
    },

    /**
     * Handle the edit action
     *
     * @returns {void}
     */
    async handleEditSubmit() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Close the dialog
      this.shouldShowEditDialog = false
      this.isMakingRequest = true

      // Use vuex store action
      await this.$store.dispatch("socialSentiments/updateItem", { ...this.selectedItem, ...this.form })

      this.isMakingRequest = false
    },

    /**
     * Handle the download action
     *
     * @returns {void}
     */
    triggerDownload(item) {
      // Dispatch the action
      this.$store.dispatch("socialSentiments/downloadItem", {
        modelId: item.id,
        triggerId: String(Date.now())
      })
    },

    /**
     * Handle the message event
     *
     * @param {Object} event
     * @returns {void}
     */
    handleMessageEvent(event) {
      this.$store.dispatch("socialSentiments/updateLocalItem", event)

      // If it was an error
      if (event.key === "social-sentiments-process-failed") {
        // Show a toast
        this.$store.dispatch("toasts/add", { text: event.localData.message || "An error occurred!" })
      }
    }
  },

  /**
   * As soon as the component is rendered
   *
   * @returns {void}
   */
  created() {
    // Dispatch action to fetch items
    this.fetchItems(false)

    // Register a subscriber for messageEvents
    messageEvents.register({
      id: subscriptionId,
      module: "social-sentiments",
      type: "all",
      key: "all",
      validator: (event) => event.module === "social-sentiments",
      callback: this.handleMessageEvent
    })
  },

  /**
   * As soon as the component is destroyed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // Unregister the subscriber for messageEvents
    messageEvents.deregister(subscriptionId)
  }
}
</script>
